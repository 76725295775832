import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import Company from '../components/company/Company';
// import '../assets/styles/index.scss';

const queryClient = new QueryClient()
const container = document.getElementById('react-company');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Company />
    </QueryClientProvider>
  </React.StrictMode>
);
